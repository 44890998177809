import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/default.js";
import { Link } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Coding Practices used in Trailguide`}</h1>
    <br />
    <ol className="list-decimal ml-5">
      <li>
        <Link to="/javascript/abstraction_not_comment" className="text-gray-900" mdxType="Link">
  Use abstractions instead of comments
        </Link>
      </li>
      <li>
        <Link to="/javascript/fail_early" className="text-gray-900" mdxType="Link">
  Fail early - be strict and do not accept crap
        </Link>
      </li>
      <li>
        <Link to="/javascript/redgreenrefactor" className="text-gray-900" mdxType="Link">
  Red, Green, Refactor - Write a test, then the code, then clean up.
        </Link>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      